export enum MIXPANEL_EVENTS {
  PART_EX_TO_DO_UPCOMING_VIEW_EVENT = 'To-Do/Upcoming View Event',
  PART_EX_TO_DO_UPCOMING_VIEW_DELIVERABLE = 'To-Do/Upcoming View Deliverable',
  TOP_BAR_HOME = 'Top Bar Home',
  PART_EX_LEFT_NAV_DELIVERABLES = 'Participant Deliverables Nav Click',
  PART_EX_LEFT_NAV_MODULES = 'Participant Modules Nav Click',
  CONSOLE_LEFT_NAV_DELIVERABLES = 'Console Deliverables Nav Click',
  CONSOLE_OVERVIEW_TAB = 'Console Overview Tab Clik',
  CONSOLE_PROJECTS_EXPORT = 'Console Projects Export Click',
  ACCELERATOR_MDDULE_SESSION_EVENT_LINK = 'Accelerator Module Session Event Click',
  ACCELERATOR_MDDULE_ADDITIONAL_LINK = 'Accelerator Modules Additional Link Click',
  HOME_ACCELERATOR_APPLY_BUTTON = 'Apply to Accelerator Click',
  HOME_ACCELERATOR_TF_LINK = 'Accelerator TF Link Click',
  PART_EX_DELIVERABLES_LIST_FILTER = 'Participant Deliverables List Filter Applied',
  CONSOLE_DELIVERABLES_LIST_FILTER = 'Console Deliverables List Filter Applied',
  SETTINGS_TAB = 'Settings Tab Click',
}
